import PreorderOzon from "../views/preorder/PreorderOzon.vue";
import PreorderWIldberries from "../views/preorder/PreorderWildberries.vue"
import PreorderYandexMarket from "../views/preorder/PreorderYandexMarket.vue";
import PricesWildberries from "../views/prices/PricesWildberries.vue";
import PricesMts from "../views/prices/PricesMts.vue";
import PricesOzon from "../views/prices/PricesOzon.vue";
import PricesMarket from "../views/prices/PricesMarket.vue";
import PricesMarketBinded from "../views/prices/PricesMarketBinded.vue";
import PricesYandexMarket from "../views/prices/PricesYandexMarket.vue";
import PricesBinds from "../views/prices/PricesBinds.vue";
import PricesCitilink from "../views/prices/PricesCitilink.vue";
import WarehouseAlertsPanel from "../views/warehouse/WarehouseAlertsPanel.vue";
import WarehouseSerialGenerator from "../views/warehouse/WarehouseSerialGenerator.vue";
import SignIn from "../views/public/SignIn.vue";
import SignUp from "../views/public/SignUp.vue";

const routes = [
    {
      path: "/",
      name: "Опт. Создание предзаказа OZON",
      component: PreorderOzon,
    },
    {
      path: "/preorder/wildberries",
      name: "Опт. Создание предзаказа Wildberries",
      component: PreorderWIldberries,
    },
    {
      path: "/preorder/yandex_market",
      name: "Опт. Создание предзаказа Яндекс.Маркет",
      component: PreorderYandexMarket,
    },
    {
      path: "/prices/wildberries",
      name: "Цены. Wildberries",
      component: PricesWildberries,
    },
    {
      path: "/prices/mts",
      name: "Цены. МТС",
      component: PricesMts,
    },
    {
      path: "/prices/ozon",
      name: "Цены. OZON",
      component: PricesOzon,
    },
    {
      path: "/prices/citilink",
      name: "Цены. Ситилинк",
      component: PricesCitilink,
    },
    {
      path: "/prices/market_offers",
      name: "Цены. Рынок",
      component: PricesMarket,
    },
    {
      path: "/prices/market_offers_binded",
      name: "Связанные цены. Рынок",
      component: PricesMarketBinded,
    },
     {
      path: "/prices/yandex_market",
      name: "Цены. ЯндексМаркет",
      component: PricesYandexMarket,
    },
    {
      path: "/prices/binds",
      name: "Цены. Связи",
      component: PricesBinds,
    },
    {
      path: "/warehouse/alerts_panel",
      name: "Склад. Панель отгрузок",
      component: WarehouseAlertsPanel,
    },
    {
      path: "/warehouse/serial",
      name: "Склад. Генерация серийных номеров",
      component: WarehouseSerialGenerator,
    },
    {
      path: "/signin",
      name: "Авторизация",
      component: SignIn,
    },
    {
      path: "/signup",
      name: "Регистрация",
      component: SignUp,
    },
  ];

  export default routes