export default {
  namespaced: true,
  state: () => ({
    binds: { products: null, merchants_count: null },
    binds_old: { products: null, merchants_count: null },
  }),

  mutations: {
    setBinds(state, binds) {
      state.binds = binds;
    },
    setBindsOld(state, binds_old) {
      state.binds_old = binds_old;
    },
  },

  getters: {
    getBinds(state) {
      return state.binds;
    },
     getBindsOld(state) {
      return state.binds_old;
    },
  },
};
