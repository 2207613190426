// import AuthService from '../services/auth.service';
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export default {
  namespaced: true,
  state: initialState,
  actions: {
    accessToken({ commit }, accessToken) {
      commit("accessToken", accessToken);
    },
  },
  mutations: {
    accessToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = { ...state.user, accessToken: null };
    },
  },
  getters: {
    getStatus(state) {
      return state.status;
    },
  },
};
