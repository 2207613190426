import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(
        error.response ? { message: error.response.data.error_message } : error
      );
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/user/signin" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await axiosInstance.post("/user/refresh", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            const { accessToken, refreshToken } = rs.data;
            store.dispatch("auth/accessToken", accessToken);
            TokenService.updateLocalAccessToken(accessToken);
            TokenService.updateLocalRefreshToken(refreshToken);
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(
        err.response ? { message: err.response.data.error_message } : err
      );
    }
  );
};

export default setup;
