<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      name="Цены. Рынок"
      :columns="columns"
      :data="getPricesMarket?.data"
      :pagination="getPricesMarket?.pagination"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateMarketPrices"
    />
  </div>
</template>

<script>
import moment from "moment";
import MainMenu from "@/components/MainMenu.vue";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
    };
  },

  components: {
    MainMenu,
    Table,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      {
        name: "Наименование",
        key: "product",
        link: this.getMessageLink,
        value: this.getProductTitle,
      },
      { name: "Цена", key: "price" },
      { name: "Страна", key: "country" },
      {
        name: "Продавец",
        key: "chat_id",
        value: this.getChatName,
        link: this.getChatLink,
      },
      { name: "Публикация", key: "date", value: this.getFormattedDate },
    ];
    this.updateMarketPrices();
  },

  computed: {
    getPricesMarket() {
      return this.$store.getters["prices/getMarket"];
    },
  },

  methods: {
    updateMarketPrices: function () {
      this.error = "";
      this.loading = true;
      UserService.getPricesMarket(arguments)
        .then(
          (response) => {
            this.$store.commit("prices/setMarket", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getChatName: function (price_line) {
      return price_line.chat ? price_line.chat.title : "";
    },
    getFormattedDate: function (price_line) {
      return moment(price_line.date).format("HH:mm DD.MM.YYYY");
    },
    getProductTitle: function (price_line) {
      return (price_line.bind ? "🔗" : "") + price_line.product;
    },
    getMessageLink: function (price_line) {
      return `https://t.me/${price_line.chat.link}/${price_line.message_id}`;
    },
    getChatLink: function (price_line) {
      return `https://t.me/${price_line.chat.link}/`;
    },
    getCountryEmoji: function (price_line) {
      const countries = { Россия: "🇷🇺" };
      const emoji = countries[price_line.country];
      return emoji || price_line.country;
    },
  },
};
</script>
