<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      caption="Цены. Ситилинк"
      :columns="columns"
      :data="getPricesCitilink?.data"
      :pagination="getPricesCitilink?.pagination"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateCitilinkPrices"
    />
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import moment from "moment";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
    };
  },

  components: {
    Table,
    MainMenu,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      { name: "Артикул", key: "id" },
      { name: "Наименование", key: "title", link: this.getCitilinkLink },
      { name: "Цена", key: "price" },
      // { name: "Цена продажи", key: "price_sale", bind: this.getPriceSaleColor },
      {
        name: "Дата обновления",
        key: "last_update",
        bind: this.getLastUpdateColor,
        value: this.getFormattedDate,
      },
    ];
    this.updateCitilinkPrices();
  },

  computed: {
    getPricesCitilink() {
      return this.$store.getters["prices/getCitilink"];
    },
  },

  methods: {
    updateCitilinkPrices: function () {
      this.loading = true;
      UserService.getPricesCitilink(arguments)
        .then(
          (response) => {
            this.$store.commit("prices/setCitilink", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    calcMoment: function (last_update) {
      return moment(new Date(last_update)).startOf("minute").fromNow();
    },
    getCitilinkLink: function (line) {
      return `https://www.citilink.ru/product/s-${line.id}`;
      // https://www.citilink.ru/product/0-M00219798
    },
    getFormattedDate: function (line) {
      return moment(new Date(line.last_update)).startOf("minute").fromNow();
    },
    getRestColor: function (line) {
      return (
        "table-" +
        (line.rest < 10 ? "danger" : line.rest < 20 ? "warning" : "")
      );
    },
    getPriceColor: function (line) {
      return (
        "table-" +
        (!line.price_sale
          ? "danger"
          : line.price_sale == line.price
          ? "primary"
          : "")
      );
    },
    getPriceSaleColor: function (line) {
      return (
        "table-" +
        (line.price_sale > line.price
          ? "danger"
          : line.price_sale == line.price
          ? "primary"
          : "")
      );
    },
    getLastUpdateColor: function (line) {
      const difference =
        new Date().getTime() - new Date(line.last_update).getTime();
      return difference > 15*60*1000 ? "table-danger" : "";
    },
  },
};
</script>

<style scoped>
</style>
