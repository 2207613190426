<template>
  <div class="input-group input-group-sm" :ref="myid">
    <input
      type="text"
      class="form-control bg-white p-0 ps-1"
      :placeholder="placeholder"
      :value="selected"
      readonly
    />
    <button
      class="btn btn-sm btn-outline-primary p-0 px-1 button-icon"
      type="button"
      v-if="selected"
      @click="clear()"
    >
      <BIconX></BIconX>
    </button>
    <button
      class="
        btn btn-sm btn-outline-primary
        p-0
        px-1
        button-icon
        dropdown-button
      "
      v-bind:class="show ? ' visible' : ''"
      type="button"
      @click="show = !show"
    >
      <BIconCaretDownFill></BIconCaretDownFill>
    </button>
    <ul
      class="dropdown-menu p-0"
      v-bind:class="show ? ' show' : ''"
      x-placement="bottom-start"
      style="overflow: auto; max-height: 300px"
    >
      <li
        class="form-check ms-1 noselect"
        role="button"
        v-for="(line, index) in list"
        :key="line"
      >
        <input
          class="form-check-input"
          type="checkbox"
          role="button"
          :checked="line.selected"
          @click="select(index)"
          :id="myid + '-' + index"
        />
        <label
          class="form-check-label w-100 small"
          role="button"
          :for="myid + '-' + index"
        >
          {{ line.value }}
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
import { BIconCaretDownFill, BIconX } from "bootstrap-icons-vue";
import { uuid } from "vue-uuid";
export default {
  props: {
    data: Array,
    field: String,
    placeholder: String,
    change: Function,
    enabled: Boolean,
    value: Function,
  },
  data() {
    return {
      myid: null,
      show: false,
      list: [],
    };
  },
  computed: {
    filtered: function () {
      return this.list.filter((line) => line.selected);
    },
    filtered_fields: function () {
      return this.filtered.map((line) => line.key);
    },
    selected: function () {
      const checked = this.filtered;
      return checked.length > 1
        ? "Выбрано " + checked.length
        : checked.length == 1
        ? checked[0].value
        : "";
    },
  },
  created() {
    this.myid = uuid.v4();
    let list = [
      ...new Set(
        this.data.map((line) => {
          return {
            selected: false,
            key: line[this.field],
            value: this.value ? this.value(line) : line[this.field],
          };
        })
      ),
    ];
    list = list.filter(
      (elem, index, self) =>
        self.findIndex((t) => {
          return t.key === elem.key;
        }) === index
    );
    list.sort(function (a, b) {
      return a.value ? a.value.localeCompare(b.value) : a.value == b.value;
    });
    this.list = list;
    document.addEventListener("click", this.dropdown);
  },
  unmounted() {
    document.removeEventListener("click", this.dropdown);
  },

  components: { BIconCaretDownFill, BIconX },
  methods: {
    dropdown(e) {
      let el = this.$refs[this.myid];
      let target = e.target;
      if (this.show && el !== target && !el.contains(target)) {
        this.show = false;
        if (this.change) {
          this.change(this.field, this.filtered, this.filtered_fields);
        }
      }
    },
    select(index) {
      this.list[index].selected = !this.list[index].selected;
    },
    clear() {
      this.list = this.list.map((line) => {
        return { ...line, selected: false };
      });
      if (this.change) {
        this.change(this.field, this.filtered, this.filtered_fields);
      }
    },
  },
};
</script>

<style scoped>
.button-icon {
  line-height: 0.5;
}

.dropdown-menu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 22px, 0px);
  width: -webkit-fill-available;
}

.dropdown-button {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}
.dropdown-button .visible {
  border-bottom-right-radius: 0 !important;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>