export default {
  namespaced: true,
  state: () => ({
    binds: { products: null, merchants_count: null },
    stat: null,
    orders: null,
  }),

  mutations: {
    setBinds(state, binds) {
      state.binds = binds;
    },
    setStat(state, stat) {
      state.stat = stat;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
  },

  getters: {
    getBinds(state) {
      return state.binds;
    },
    getStat(state) {
      return state.stat;
    },
    getOrders(state) {
      return state.orders;
    },
  },
};
