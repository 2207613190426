import { createRouter, createWebHistory } from "vue-router";
import tokenService from "@/services/token.service.js";
import routes from './routes.js'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Alikson - " + to.name;
  next();
});

router.beforeEach((to, from, next) => {
  let token_valid = tokenService.getUser();
  const publicPages = ["/signin", "/signup"];
  const authRequired = publicPages.includes(to.path);
  if ((token_valid && !authRequired) || (!token_valid && authRequired)) {
    next();
  } else if (token_valid && authRequired) {
    next("/");
  } else {
    next("/signin");
  }
});

export default router;
