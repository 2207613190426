<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error.message || error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <div class="container g-0">
      <div class="row row-cols-1 gx-2">
        <div class="col-lg-6 col-md-6">
          <div class="border rounded p-2">
            <div class="row g-2">
              <div class="col-md-6 col-lg-2 pt-1 pb-0">
                <h5 class="mb-0" style="white-space: nowrap">
                  Генерация серийных номеров
                </h5>
              </div>
              <div style="flex-grow: 1 !important"></div>
            </div>
            <div class="container-fluid border rounded mt-2 p-1 mx-0 bg-light">
              <div class="input-group input-group-sm">
                <input
                  type="text"
                  class="form-control bg-white p-0 ps-1"
                  placeholder="Код товара"
                  style="max-width: 84px"
                  v-model="code"
                />
                <input
                  type="text"
                  class="form-control bg-white p-0 ps-1"
                  placeholder="Наименование"
                  v-model="title"
                />
                <input
                  type="text"
                  class="form-control bg-white p-0 ps-1"
                  placeholder="Количество"
                  style="max-width: 84px"
                  v-model="count"
                />
                <div class="input-group-append">
                  <button
                    :disabled="Number(count) <= 0 || Number(count) > 100"
                    class="btn btn-primary btn-sm"
                    type="button"
                    @click="generate()"
                  >
                    Сгенерировать
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MainMenu from "@/components/MainMenu.vue";
import UserService from "@/services/user.service";
import Loading from "vue-loading-overlay";
import { jsPDF } from "jspdf";

export default {
  data: () => {
    return {
      error: "",
      loading: false,
      code: null,
      title: null,
      count: null,
    };
  },

  components: {
    MainMenu,
    Loading,
  },

  mounted() {
    moment.locale("RU");
  },

  computed: {
    getData() {
      const data = this.$store.getters["preorder/getData"];
      if (data.preorder)
        data.preorder.forEach((element) => {
          element.double =
            data.preorder.filter((order) => order.title == element.title)
              .length > 1;
        });
      return data.preorder;
    },
  },

  methods: {
    generate: function () {
      this.error = "";
      this.loading = true;
      UserService.getWarehouseSerial({ count: Number(this.count) })
        .then(
          (response) => {
            var img = new Image();
            img.src = "/serial.png";
            const doc = new jsPDF("l", "mm", [43.5, 22]);
            doc.addFont("/roboto.ttf", "roboto", "normal");
            doc.addFont("/roboto_bold.ttf", "roboto_bold", "bold");
            const code = this.code?.trim();
            let title = this.title;
            if (title) {
              title = doc.splitTextToSize(title.trim(), 110);
            }
            for (let i = 0; i < response.data.data.length; i++) {
              const barcode = response.data.data[i].barcode;
              if (i > 0) {
                doc.addPage();
              }
              doc.addImage(barcode, "png", 0, 4, 43, 22);
              if (code) {
                doc.setFontSize(10);
                doc.setFont("roboto_bold", "bold");
                doc.text(code, 1.5, 4);
              }
              if (title) {
                doc.setFontSize(5);
                doc.setFont("roboto", "normal");
                doc.text(title, 1.8, 2.5 + (code ? 4 : 0));
              }
              doc.setFontSize(11);
              doc.setFont("roboto", "normal");
              doc.text(response.data.data[i].code, 8, 21);
            }
            doc.save(".pdf");
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
