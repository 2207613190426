<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      name="Цены. Яндекс.Маркет"
      :columns="columns"
      :data="getPricesYandexMarket?.data"
      :pagination="getPricesYandexMarket?.pagination"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateYandexMarketPrices"
    />
  </div>
</template>

<script>
import moment from "moment";
import MainMenu from "@/components/MainMenu.vue";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
    };
  },

  components: {
    MainMenu,
    Table,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      { name: "Категория", key: "category_id" },
      { name: "Наименование", key: "title", link: this.getMarketLink },
      { name: "❶ MIN", key: "price_min" },
      { name: "❷ MIN", key: "price_min_second" },
      {
        name: "OZON",
        key: "ozon_price",
        link: this.getOzonLink,
        value: this.getOzonData,
        bind: this.getOzonPriceColor,
      },
      {
        name: "МТС",
        key: "mts_price",
        link: this.getMtsLink,
        value: this.getMtsData,
        bind: this.getMtsPriceColor,
      },
      {
        name: "Наценка",
        key: "margin",
        value: this.getMargin,
        bind: this.getMarginColor,
      },
      { name: "Предложений", key: "shop_count", bind: this.getOffersColor },
      { name: "Отзывов", key: "reviews_count", bind: this.getReviewsColor },
      { name: "Обновлено", key: "updated", value: this.getFormattedDate },
      { name: "Промокод", key: "promo" },
    ];
    this.updateYandexMarketPrices();
  },

  computed: {
    getPricesYandexMarket() {
      return this.$store.getters["prices/getYandexMarket"];
    },
  },

  methods: {
    updateYandexMarketPrices: function () {
      this.loading = true;
      UserService.getPricesYandexMarket(arguments)
        .then(
          (response) => {
            this.$store.commit("prices/setYandexMarket", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getFormattedDate: function (price_line) {
      return moment(new Date(price_line.updated)).format("HH:mm DD.MM.YYYY");
    },
    getMarketLink: function (price_line) {
      return `https://market.yandex.ru/product/${price_line.id}/offers?sku=${price_line.sku}&how=aprice`;
    },
    getOzonLink: function (line) {
      return line.ozon_id
        ? `https://www.ozon.ru/product/${line.ozon_id}/`
        : null;
    },
    getOzonData: function (line) {
      return line.ozon_price
        ? Math.round(line.ozon_price, 0).toString() +
            " (" +
            Math.round(line.ozon_weight * 100, -2).toString() +
            "%)"
        : null;
    },
    getOzonPriceColor: function (price_line) {
      return price_line.ozon_price &&
        price_line.ozon_price < price_line.price_min
        ? "table-danger"
        : "";
    },
    getMtsLink: function (line) {
      return line.mts_id
        ? `https://shop.mts.ru/product/${line.mts_slug}`
        : null;
    },
    getMtsData: function (line) {
      return line.mts_price
        ? Math.round(line.mts_price, 0).toString() +
            " (" +
            Math.round(line.mts_weight * 100, -2).toString() +
            "%)"
        : null;
    },
    getMtsPriceColor: function (price_line) {
      return price_line.mts_price &&
        price_line.mts_price < price_line.price_min
        ? "table-danger"
        : "";
    },
    getMargin: function (price_line) {
      return Math.round(price_line.margin, -2).toString() + "%";
    },
    getReviewsColor: function (price_line) {
      return price_line.reviews_count >= 30 ? "table-success" : "";
    },
    getOffersColor: function (price_line) {
      return price_line.shop_count >= 15 ? "table-success" : "";
    },
    getMarginColor: function (price_line) {
      return price_line.margin >= 60
        ? "table-danger"
        : price_line.margin >= 40
        ? "table-warning"
        : price_line.margin >= 20
        ? "table-success"
        : "";
    },
  },
};
</script>

<style scoped>
</style>
