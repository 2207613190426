<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error.message || error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      name="Предзаказ"
      primary="id"
      :search_enabled="true"
      :columns="columns"
      :data="getData"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateYandexMarketPrices"
      :excel_fields="excel_fields"
      :buttons="[
        {
          caption: 'Сформировать',
          callback: calculate,
          params: { with_prices: false },
        },
        {
          caption: 'Сформировать с ценами',
          callback: calculate,
          params: { with_prices: true },
        },
      ]"
      :virtual="true"
    />
  </div>
</template>

<script>
import moment from "moment";
import MainMenu from "@/components/MainMenu.vue";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import { copyText } from "vue3-clipboard";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
      excel_fields: {
        Бренд: "brand",
        Наименование: "title",
        Цена: "price",
        "Цена продажи": "price_sale",
      },
    };
  },

  components: {
    MainMenu,
    Table,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      {
        name: "Бренд",
        key: "brand",
        filter: {
          type: "multi",
        },
      },
      {
        name: "Наименование",
        key: "title",
        filter: {
          type: "multi",
        },
        link: this.getLink,
      },
      {
        name: "Категория",
        key: "category",
        filter: {
          type: "multi",
        },
      },
      {
        name: "Цена",
        key: "price",
        value: (line) => {
          return Number(line.price).toFixed(0);
        },
        button: {
          callback: (column, line) => {
            line.price_sale = (line.price * 1.04).toFixed(2);
            line.charge = 4;
          },
        },
      },
      {
        name: "Количество",
        key: "rest",
      },
      {
        name: "Цена продажи",
        key: "price_sale",
        input: {
          type: "number",
          placeholder: "Цена продажи",
          after_text: "₽",
          clear: (line) => {
            delete line.price_sale;
            delete line.charge;
          },
        },
        width: "140px",
        update: (line, value) => {
          const price = line.price;
          line.price_sale = value;
          line.charge = (((value - price) / price) * 100).toFixed(2);
        },
        value: function (line) {
          return line.price_sale || "";
        },
      },
      {
        name: "Наценка",
        key: "charge",
        input: {
          type: "number",
          placeholder: "Наценка",
          after_text: "%",
          clear: (line) => {
            delete line.price_sale;
            delete line.charge;
          },
          bind_class: (line) => {
            return line.charge < 3 ? " is-invalid" : "";
          },
        },
        width: "105px",
        update: (line, value) => {
          line.charge = value;
          line.price_sale = ((1 + value * 0.01) * line.price).toFixed(2);
        },
        value: function (line) {
          return line.charge || "";
        },
      },
      {
        name: "Обновление",
        key: "last_update",
        value: this.getFormattedDate,
      },
    ];
    this.updateYandexMarketPrices();
  },

  computed: {
    getData() {
      const data = this.$store.getters["preorderYandexMarket/getData"];
      if (data.preorder)
        data.preorder.forEach((element) => {
          element.double =
            data.preorder.filter((order) => order.title == element.title)
              .length > 1;
        });
      return data.preorder;
    },
  },

  methods: {
    updateYandexMarketPrices: function () {
      this.error = "";
      this.loading = true;
      UserService.getPreorderYandexMarket()
        .then(
          (response) => {
            this.$store.commit("preorderYandexMarket/setData", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getFormattedDate: function (price_line) {
      return moment(price_line.last_update).startOf("minute").fromNow();
    },
    getLink: function (line) {
      return `https://market.yandex.ru/product/${line.product_id}/offers?how=aprice&sku=${line.id}`;
    },
    calculate: function (event, params) {
      if (event) {
        let preorder = "";
        let last_model;
        let model;
        this.$store.getters["preorderYandexMarket/getPreorder"].forEach(
          (product) => {
            if (
              product.price_sale &&
              product.charge &&
              product.visible &&
              (this.minimalCount == null || product.rest >= this.minimalCount)
            ) {
              model = product.brand
              if (model != last_model) preorder += "\n";
              preorder += product.brand + " / " + product.title + " ";
              if (params && params.with_prices) {
                preorder +=
                  "Цена " + product.price + " Наценка " + product.charge + "% ";
              }
              preorder += "**" + Number(product.price_sale).toFixed(0) + "**\n";
              last_model = model;
            }
          }
        );
        copyText(preorder.trim(), undefined, (error) => {
          if (error) {
            alert("Ошибка копирования предзаказа!");
          } else {
            alert("Предзаказ скопирован в буфер обмена!");
          }
        });
      }
    },
  },
};
</script>
