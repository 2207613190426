<template>
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <div
    v-if="loading"
    class="spinner-grow m-3 text-warning"
    role="status"
    style="position: fixed; bottom: 0; width: 5rem; height: 5rem"
  >
    <span class="visually-hidden">Загрузка...</span>
  </div>
  <a
    class="btn btn-outline-warning m-3"
    role="button"
    style="position: fixed; bottom: 0; right: 0"
    href="/"
    >На главную</a
  >
  <select
    class="form-select btn btn-outline-warning"
    role="button"
    style="position: fixed; bottom: 15px; left: 15px; width: 200px"
    @change="switchSelect($event)"
  >
    <option selected value="b6f019eb-a98c-11eb-0a80-06a4001a2be7">
      Нововладыкино
    </option>
    <!-- <option value="563a5bd8-5f64-11ec-0a80-0cc3002c7cdb">Горбушка</option> -->
  </select>
  <div class="container-fluid" :key="getAlertOrders">
    <h1 class="text-white" v-if="getAlertOrders && getAlertOrders.length == 0">
      Список заказов пуст
    </h1>
    <div class="row" v-for="alertOrder in getAlertOrders" :key="alertOrder">
      <div
        class="col border-1"
        v-bind:class="getMerchantColor(alertOrder.marketplace_id)"
      >
        <div style="font-size: 80px; font-weight: bold">
          {{ alertOrder.merchant_name }}
        </div>
        <div style="font-size: 30px; font-weight: bold">
          {{ alertOrder.order_id }}
        </div>
      </div>
      <div
        class="col"
        v-bind:class="getDateBoxColor(alertOrder.shipment_date_to)"
      >
        <div style="font-size: 60px; font-weight: bold">
          {{ getDate(alertOrder.shipment_date_to) }}
        </div>
        <div style="font-size: 60px; font-weight: bold">
          {{ getTime(alertOrder.shipment_date_to) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UserService from "@/services/user.service";

export default {
  name: "Warehouse Alert Orders",

  data: () => {
    return {
      error: "",
      loading: false,
      ordersOld: [],
      selected: "b6f019eb-a98c-11eb-0a80-06a4001a2be7",
    };
  },

  mounted() {
    document.body.classList.add("alerts-body");
    moment.locale("RU");
    this.updateData();
  },
  unmounted() {
    document.body.classList.remove("alerts-body");
  },

  computed: {
    getAlertOrders() {
      return this.$store.getters["warehouse/getAlertOrders"];
    },
  },

  methods: {
    switchSelect(event) {
      this.selected = event.target.value;
    },
    getDateBoxColor: function (shipment_date_to) {
      const difference =
        new Date(shipment_date_to).getTime() - new Date().getTime();
      if (difference > 20 * 60 * 1000) {
        return "bg-success text-white";
      } else if (difference > 10 * 60 * 1000) {
        return "bg-warning";
      } else {
        return "bg-danger text-white";
      }
    },
    getMerchantColor: function (merketplace_id) {
      switch (merketplace_id) {
        case 2:
          return "text-dark bg-market";
        case 3:
          return "text-white bg-ozon ";
        default:
          return "text-white bg-dark";
      }
    },
    getDate: function (shipment_date_to) {
      return moment(shipment_date_to).format("D MMMM");
    },
    getTime: function (shipment_date_to) {
      return moment(shipment_date_to).format("HH:mm");
    },
    updateData: function () {
      this.error = "";
      this.loading = true;
      UserService.getWarehouseAlertOrders()
        .then(
          (response) => {
            let data = response.data.data;
            this.$store.commit("warehouse/setAlertOrders", data);
            let new_order = false;
            let alert_order = false;
            data.forEach((order) => {
              if (!alert_order) {
                alert_order =
                  new Date(order.shipment_date_to).getTime() -
                    new Date().getTime() <
                  10 * 60 * 1000;
              }
              if (!new_order) {
                new_order =
                  this.ordersOld.length == 0 ||
                  this.ordersOld.filter((orderOld) => {
                    orderOld.order_id = order.order_id;
                  }).length > 0;
              }
            });
            if (new_order) {
              this.playSound("/sounds/order.mp3");
            }
            if (alert_order) {
              this.playSound("/sounds/siren.mp3");
            }
            if (data.length == 0 && this.ordersOld.length > 0) {
              this.playSound("/sounds/orders_empty.mp3");
            }
            this.ordersOld = data;
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
          setTimeout(() => this.updateData(this), 10000);
        });
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
  },
};
</script>

<style>
.alerts-body {
  min-height: 100vh;
  background-color: #343a40;
}
.bg-market {
  background-color: #fc0;
}
.bg-ozon {
  background-color: #005bff;
}
</style>
