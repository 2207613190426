<template>
  <nav
    class="
      navbar navbar-expand-lg navbar-light
      bg-light
      position-fixed
      headline
    "
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img
          src="/favicon.svg"
          alt=""
          width="30"
          height="24"
          class="d-inline-block align-text-top"
        />
        Alikson
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <div
              class="nav-link dropdown-toggle"
              v-bind:class="{
                ' active': [
                  '/',
                  '/preorder/wildberries',
                  '/preorder/yandex_market',
                  '/preorder/yandex_market/new',
                ].includes(currentPath),
              }"
              role="button"
              data-bs-toggle="dropdown"
            >
              Оптовые продажи
            </div>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/')"
                  >Создание предзаказа OZON</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/preorder/wildberries',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/preorder/wildberries')"
                  >Создание предзаказа Wildberries</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/preorder/yandex_market',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/preorder/yandex_market')"
                  >Создание предзаказа Яндекс.Маркет</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <div
              class="nav-link dropdown-toggle"
              v-bind:class="{
                ' active': [
                  '/prices/wildberries',
                  '/prices/mts',
                  '/prices/market_offers',
                  '/market_offers_binded',
                  '/prices/ozon',
                  '/prices/citilink',
                  '/prices/yandex_market',
                  '/prices/binds',
                ].includes(currentPath),
              }"
              role="button"
              data-bs-toggle="dropdown"
            >
              Цены
            </div>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/mts',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/prices/mts')"
                  >МТС</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/market_offers',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/prices/market_offers')"
                  >Рынок</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/market_offers_binded',
                  }"
                  role="button"
                  v-on:click="
                    this.$router.replace('/prices/market_offers_binded')
                  "
                  >Рынок со связями</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/citilink',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/prices/citilink')"
                  >Ситилинк</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/yandex_market',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/prices/yandex_market')"
                  >Яндекс.Маркет</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/ozon',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/prices/ozon')"
                  >OZON</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/prices/wildberries',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/prices/wildberries')"
                  >Wildberries</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <div
              class="nav-link dropdown-toggle"
              v-bind:class="{
                ' active': [
                  '/warehouse/alerts_panel',
                  '/warehouse/serial',
                ].includes(currentPath),
              }"
              role="button"
              data-bs-toggle="dropdown"
            >
              Склад
            </div>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/warehouse/alerts_panel',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/warehouse/alerts_panel')"
                  >Панель отгрузок</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-bind:class="{
                    ' active': currentPath == '/warehouse/serial',
                  }"
                  role="button"
                  v-on:click="this.$router.replace('/warehouse/serial')"
                  >Генерация серийных номеров</a
                >
              </li>
            </ul>
          </li>
          <li>
            <div role="button" class="nav-link" v-on:click="logout">Выйти</div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div :style="{ height: '56px' }"></div>
</template>

<script>
// href="/prices/wildberries"
import tokenService from "@/services/token.service.js";
export default {
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    logout: function () {
      this.$store.commit("auth/logout");
      tokenService.removeUser();
      this.$router.replace("/signin");
    },
  },
};
</script>

<style scoped>
.headline {
  z-index: 999;
  width: 100%;
}
</style>
