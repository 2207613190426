import api from "./api";

class UserService {
  getPreorder() {
    return api.get("/wholesale/preorder/ozon");
  }
  getPreorderWildberries() {
    return api.get("/wholesale/preorder/wildberries");
  }
  getPreorderYandexMarket() {
    return api.get("/wholesale/preorder/yandex_market");
  }
  getMarketplaceBindsOld() {
    return api.get("/marketplace/binds/old");
  }
  getMarketplaceBinds(data) {
    return api.post("/marketplace/binds", data[0]);
  }
  getDropBinds() {
    return api.get("/drop/binds");
  }
  getDropStat() {
    return api.get("/drop/stat");
  }
  getDropOrders(marketplace_id) {
    return api.get("/drop/orders", {
      params: { marketplace_id: marketplace_id },
    });
  }
  getMarketplaces() {
    return api.get("/marketplaces");
  }
  getPricesWildberries(data) {
    return api.post("/prices/wildberries", data[0]);
  }
  getPricesMts(data) {
    return api.post("/prices/mts", data[0]);
  }
  getPricesCitilink(data) {
    return api.post("/prices/citilink", data[0]);
  }
  getPricesOzon(data) {
    return api.post("/prices/ozon", data[0]);
  }
  getPricesMarket(data) {
    return api.post("/prices/market", data[0]);
  }
  getPricesMarketBinded(data) {
    return api.post("/prices/market/binded", data[0]);
  }
  getPricesYandexMarket(data) {
    return api.post("/prices/yandex_market", data[0]);
  }
  getWarehouseSerial(data) {
    return api.post("/warehouse/serial", data);
  }
  getPricesMarketChats() {
    return api.get("/prices/market_chats");
  }
  getWarehouseAlertOrders() {
    return api.get("/warehouse/alert_orders");
  }
}

export default new UserService();
