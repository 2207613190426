export default {
  namespaced: true,
  state: () => ({
    data: { preorder: null, categories: null },
  }),

  mutations: {
    setData(state, data) {
      data.categories.forEach((element) => {
        element.checked = true;
      });
      data.preorder.forEach((element) => {
        element.visible = true;
      });
      state.data = data;
    },
    setCategoryChecked(state, category_id) {
      let checked;
      state.data.categories.forEach((element) => {
        if (element.id == category_id) {
          element.checked = !element.checked;
          checked = element.checked;
        }
      });
      state.data.preorder.forEach((element) => {
        if (element.category_id == category_id) element.visible = checked;
      });
    },
    setCharge(state, { id, charge }) {
      state.data.preorder.forEach((element) => {
        if (element.id == id) {
          element.charge = charge;
          element.price_sale = ((1 + charge * 0.01) * element.price).toFixed(2);
        }
      });
    },
    setPriceSale(state, { id, priceSale }) {
      state.data.preorder.forEach((element) => {
        if (element.id == id) {
          element.charge = (
            ((priceSale - element.price) / element.price) *
            100
          ).toFixed(2);
          element.price_sale = priceSale;
        }
      });
    },
    setEmpty(state, id) {
      state.data.preorder.forEach((element) => {
        if (element.id == id) {
          element.charge = undefined;
          element.price_sale = undefined;
        }
      });
    },
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getCategories(state) {
      return state.data.categories;
    },
    getPreorder(state) {
      return state.data.preorder;
    },
  },
};
