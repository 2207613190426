export default {
  namespaced: true,
  state: () => ({
    alertOrders: null,
  }),

  mutations: {
    setAlertOrders(state, alertOrders) {
      state.alertOrders = alertOrders;
    },
  },

  getters: {
    getAlertOrders(state) {
      return state.alertOrders;
    },
  },
};
