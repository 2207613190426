<template >
  <div class="login-form text-center vh-100">
    <div class="form-signin">
      <img class="mb-4" src="favicon.svg" alt="" width="72" height="72" />
      <h1 class="h3 mb-3 font-weight-normal">Авторизация</h1>
      <input
        type="text"
        class="form-control"
        placeholder="Логин"
        required
        autofocus
        v-model="Username"
        id="username"
      />
      <input
        type="password"
        class="form-control"
        placeholder="Пароль"
        required
        v-model="Password"
        id="password"
      />
      <div
        class="alert"
        v-bind:class="'alert-' + Message.type"
        role="alert"
        v-if="Message.text"
      >
        {{ Message.text }}
      </div>
      <button
        class="btn btn-lg btn-primary btn-block"
        :disabled="Loading"
        v-on:click="signIn"
      >
        Войти
      </button>
      <div class="m-3">Нет аккаунта? <a href="/signup">Создать</a></div>
      <p class="mt-5 mb-3 text-muted">&copy; Alikson 2020-2021</p>
    </div>
  </div>
</template>

<script>
import authService from "@/services/auth.service.js";
export default {
  name: "SignIn Form",
  props: {
    username: String,
    password: String,
    message: {
      type: Object,
      default: () => ({ text: null, type: null }),
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      Loading: this.loading,
      Username: this.username,
      Password: this.password,
      Message: this.message,
    };
  },
  methods: {
    async signIn() {
      this.Message = { text: null };
      this.Loading = true;
      if (!this.validate(this.Username, 6, 20)) {
        return (this.Message = {
          text: "Длина логина должна быть от 6 до 20 символов",
          type: "warning",
        });
      }
      if (!this.validate(this.Password, 8, 32)) {
        return (this.Message = {
          text: "Длина пароля должна быть от 8 до 32 символов",
          type: "warning",
        });
      }
      try {
        await authService.login({
          username: this.Username,
          password: this.Password,
        });
        this.Message = {
          text: "Переадресация...",
          type: "success",
        };
        this.$router.replace("/");
      } catch (error) {
        this.Message = { text: error.message, type: "danger" };
      }
      this.Loading = false;
    },
    validate(value, min, max) {
      return value && value.length >= min && value.length <= max;
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.login-form {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
