import authModule from "./auth.module.js";
import preorderModule from "./preorder.module.js";
import preorderWildberriesModule from "./preorderWildberries.module.js";
import preorderYandexMarketModule from "./preorderYandexMarket.module.js";
import marketplaceModule from "./marketplace.module.js";
import dropModule from "./drop.module.js";
import pricesModule from "./prices.module.js";
import warehouseModule from "./warehouse.module.js";
import { createStore } from "vuex";
export default createStore({
  modules: {
    auth: authModule,
    preorder: preorderModule,
    preorderWildberries: preorderWildberriesModule,
    preorderYandexMarket: preorderYandexMarketModule,
    marketplace: marketplaceModule,
    drop: dropModule,
    prices: pricesModule,
    warehouse: warehouseModule,
  },
});
