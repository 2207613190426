<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error.message || error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      name="Цены. Рынок со связями"
      :search_enabled="true"
      :columns="columns"
      :data="getPricesMarket?.data"
      :pagination="getPricesMarket?.pagination"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateMarketPrices"
      :excel_fields="excel_fields"
    />
  </div>
</template>

<script>
import moment from "moment";
import MainMenu from "@/components/MainMenu.vue";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
      excel_fields: {
        Наименование: "name",
        Цена: "price_min",
      },
    };
  },

  components: {
    MainMenu,
    Table,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      {
        name: "Код",
        key: "code",
      },
      {
        name: "Наименование",
        key: "name",
      },
      {
        name: "Минимальная цена",
        key: "prices",
        value: this.getMinPrice,
        link: this.getMinPriceLink,
        not_sort: true,
      },
      {
        name: "Средняя цена",
        key: "prices",
        value: this.getAvgPrice,
        not_sort: true,
      },
      {
        name: "Продавец",
        key: "prices",
        value: this.getMinSeller,
        link: this.getChatLink,
        not_sort: true,
      },
      {
        name: "Публикация",
        key: "prices",
        value: this.getFormattedDate,
        not_sort: true,
      },
      {
        name: "",
        value: () => {
          return "Цены";
        },
        popper: {
          html: this.getAllPricesTable,
        },
      },
    ];
    this.updateMarketPrices();
  },

  computed: {
    getPricesMarket() {
      return this.$store.getters["prices/getMarketBinded"];
    },
  },

  methods: {
    updateMarketPrices: function () {
      this.error = "";
      this.loading = true;
      UserService.getPricesMarketBinded(arguments)
        .then(
          (response) => {
            response.data.data.forEach((line) => {
              line.price_min = line.prices.length ? line.prices[0].price : "";
            });
            this.$store.commit("prices/setMarketBinded", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getMinSeller: function (price_line) {
      return price_line.prices.length ? price_line.prices[0].chat.title : "";
    },
    getMinPrice: function (price_line) {
      return price_line.prices.length ? price_line.prices[0].price : "";
    },
    getCorrectLink: function (chat) {
      return "https://t.me/" + (chat.private ? "joinchat/" : "") + chat.link;
    },
    getMinPriceLink: function (price_line) {
      return price_line.prices.length
        ? this.getCorrectLink(price_line.prices[0].chat) +
            "/" +
            price_line.prices[0].message_id
        : "#";
    },
    getAvgPrice: function (price_line) {
      if (!price_line.prices.length) {
        return "";
      }
      let sum = 0;
      price_line.prices.forEach((price) => {
        sum = sum + Number(price.price);
      });
      return Math.floor(sum / price_line.prices.length);
    },
    getChatName: function (price_line) {
      return price_line.chat ? price_line.chat.title : "";
    },
    getFormattedDate: function (price_line) {
      return price_line.prices.length
        ? moment(price_line.prices[0].date).format("HH:mm DD.MM.YYYY")
        : "";
    },
    getChatLink: function (price_line) {
      return price_line.prices.length
        ? this.getCorrectLink(price_line.prices[0].chat)
        : "#";
    },
    getAllPricesTable: function (column, line) {
      if (!line.prices.length) {
        return "Нет цен";
      }
      let message = "";
      line.prices.forEach((price) => {
        message += `<li class="list-group-item d-flex justify-content-between align-items-center p-2">
                      ${price.chat.title}
                      <a target="blank" href="https://t.me/${
                        (price.chat.private ? "joinchat/" : "") +
                        price.chat.link
                      }" class="btn btn-outline-primary btn-sm ms-2 p-0 px-1" role="button">${
          price.price
        }</a>
                    </li>`;
      });
      return '<ul class="list-group">' + message + "</ul>";
    },
  },
};
</script>

