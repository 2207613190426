<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      name="Цены. МТС"
      :columns="columns"
      :data="getPricesMts?.data"
      :pagination="getPricesMts?.pagination"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateMtsPrices"
      :excel_fields="excel_fields"
    />
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import moment from "moment";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
      excel_fields: {
        Ссылка: {
          field: "slug", // nested attribute supported
          callback: (value) => {
            return `https://shop.mts.ru/product/${value}`;
          },
        },
        Артикул: "sku",
        Наименование: "title",
        Цена: "price",
        "Дата обновления": {
          field: "last_update", // nested attribute supported
          callback: (value) => {
            return moment(new Date(value)).startOf("minute").fromNow();
          },
        },
      },
    };
  },

  components: {
    Table,
    MainMenu,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      { name: "Артикул", key: "sku" },
      { name: "Наименование", key: "title", link: this.getMtsLink },
      { name: "Цена", key: "price" },
      {
        name: "Дата обновления",
        key: "last_update",
        bind: this.getLastUpdateColor,
        value: this.getFormattedDate,
      },
    ];
    this.updateMtsPrices();
  },

  computed: {
    getPricesMts() {
      return this.$store.getters["prices/getMts"];
    },
  },

  methods: {
    updateMtsPrices: function () {
      this.loading = true;
      UserService.getPricesMts(arguments)
        .then(
          (response) => {
            this.$store.commit("prices/setMts", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    calcMoment: function (last_update) {
      return moment(new Date(last_update)).startOf("minute").fromNow();
    },
    getMtsLink: function (line) {
      return `https://shop.mts.ru/product/${line.slug}`;
    },
    getFormattedDate: function (line) {
      return moment(new Date(line.last_update)).startOf("minute").fromNow();
    },
    getLastUpdateColor: function (line) {
      const difference =
        new Date().getTime() - new Date(line.last_update).getTime();
      return difference > 15 * 60 * 1000 ? "table-danger" : "";
    },
  },
};
</script>

<style scoped>
</style>
