exports.tableSort = (arr, key, mode) =>
  !mode || mode == "asc"
    ? arr.sort((a, b) =>
        typeof a[key] == "string"
          ? a[key].localeCompare(b[key])
          : a[key] - b[key]
      )
    : arr.sort((a, b) =>
        typeof b[key] == "string"
          ? b[key].localeCompare(a[key])
          : b[key] - a[key]
      );
