export default {
  namespaced: true,
  state: () => ({
    wildberries: null,
    market: null,
    market_binded: null,
    market_chats: [],
    ozon: null,
    mts: null,
    citilink: null,
    yandex_market: null,
    binds: null,
  }),

  mutations: {
    setWildberries(state, wildberries) {
      state.wildberries = wildberries;
    },
    setMarket(state, market) {
      state.market = market;
    },
    setMarketBinded(state, market_binded) {
      state.market_binded = market_binded;
    },
    setMarketChats(state, market_chats) {
      state.market_chats = market_chats;
    },
    setOzon(state, ozon) {
      state.ozon = ozon;
    },
    setMts(state, mts) {
      state.mts = mts;
    },
    setCitilink(state, citilink) {
      state.citilink = citilink;
    },
    setYandexMarket(state, yandex_market) {
      state.yandex_market = yandex_market;
    },
    setBinds(state, binds) {
      state.binds = binds;
    },
  },

  getters: {
    getWildberries(state) {
      return state.wildberries;
    },
    getMarket(state) {
      return state.market;
    },
    getMarketBinded(state) {
      return state.market_binded;
    },
    getMarketChats(state) {
      return state.market_chats;
    },
    getOzon(state) {
      return state.ozon;
    },
    getMts(state) {
      return state.mts;
    },
    getCitilink(state) {
      return state.citilink;
    },
    getYandexMarket(state) {
      return state.yandex_market;
    },
     getBinds(state) {
      return state.binds;
    },
    getChat(state) {
      return (chat_id) => {
        const find = Object.values(state.market_chats).filter(
          (market_chat) => market_chat.id == chat_id
        );
        return find ? find[0] : null
      };
    },
  },
};
