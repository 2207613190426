<template>
  <div class="container-fluid g-0">
    <div class="row row-cols-lg-auto gx-2">
      <div class="col-lg-12 col-md-6">
        <div class="border rounded p-2">
          <div class="row row-cols-lg-auto g-2">
            <div class="col-md-6 col-lg-2 pt-1 pb-0">
              <h5 class="mb-0" style="white-space: nowrap">{{ name }}</h5>
            </div>
            <div style="flex-grow: 1 !important"></div>
            <div v-if="filters.length" class="col-md-6">
              <button
                class="btn btn-primary btn-sm w-100"
                v-bind:class="showFilters ? ' active' : ''"
                @click="clickFilters()"
              >
                Фильтр
              </button>
            </div>
            <div v-if="search_enabled && !virtual" class="col-md-6">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text input-group-text-sm"
                    id="search-area"
                  >
                    Поиск
                  </span>
                </div>
                <input
                  id="search"
                  aria-describedby="search-area"
                  class="form-control"
                  type="text"
                  v-model="search"
                  @change="toPage(1)"
                />
                <button
                  v-if="search"
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  @click="(search = null), toPage(1)"
                >
                  ×
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary btn-sm w-100" @click="reload()">
                <b-icon-arrow-clockwise /> Обновить
              </button>
            </div>
            <div class="col-md-6" v-if="excel_fields && data">
              <export-excel
                class="btn btn-primary btn-sm w-100"
                :data="data"
                :fields="excel_fields"
                :worksheet="name || 'Export from table'"
                type="xls"
                :name="'export_' + getFileDateTime() + '.xls'"
              >
                <b-icon-download /> Excel
              </export-excel>
            </div>
            <div class="col-md-6" v-for="button in buttons" :key="button">
              <button
                class="btn btn-primary btn-sm w-100"
                @click="button.callback($event, button.params)"
              >
                {{ button.caption }}
              </button>
            </div>
          </div>

          <div
            v-if="filters.length && showFilters"
            class="container-fluid border rounded mt-2 p-1 mx-0 bg-light"
          >
            <div class="row g-1 justify-content-start">
              <div
                class="col-xs col-sm-3 col-md-3 col-lg-2"
                v-for="filter in filters"
                :key="filter"
              >
                <TableFilter
                  :placeholder="filter.name"
                  :data="data"
                  :field="filter.key"
                  :change="addFilter"
                  :enabled="showFilters"
                  :value="filter.value"
                ></TableFilter>
              </div>
            </div>
          </div>
          <div class="table-responsive border rounded my-2">
            <table class="table table-sm table-striped mb-0">
              <thead>
                <tr
                  v-for="column_row in columns_parent"
                  :key="column_row"
                  class="text-center"
                >
                  <th
                    class="border"
                    v-for="column in column_row"
                    :key="column.key"
                    scope="col"
                    :colspan="column.colspan"
                  >
                    {{ column.name }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="column in columns"
                    :key="column.key"
                    scope="col"
                    :role="getColumnRole(column)"
                    v-on:click="setSort(column)"
                    :disabled="column.not_sort == true"
                  >
                    {{ column.name + getSortArrow(column.key) }}
                  </th>
                </tr>
              </thead>
              <tbody :key="sort_by + sortDirection">
                <template v-for="line in getTableData" :key="line[primary]">
                  <tr>
                    <template
                      v-for="column in columns"
                      :key="column.key"
                      v-bind:class="getCellClass(column, line)"
                    >
                      <td>
                        <a
                          v-if="column.link"
                          :href="column.link(line)"
                          target="blank"
                          class="hrf"
                          >{{ getCellValue(column, line) }}</a
                        >
                        <button
                          class="btn btn-sm btn-outline-primary p-0 px-1"
                          v-else-if="column.button"
                          v-on:click="buttonCallback(column, line)"
                        >
                          {{ getCellValue(column, line) }}
                        </button>
                        <template v-else-if="column.popper">
                          <Popper>
                            <button
                              class="btn btn-sm btn-outline-primary p-0 px-1"
                            >
                              {{ getCellValue(column, line) }}
                            </button>
                            <template #content>
                              <div
                                v-html="column.popper.html(column, line)"
                              ></div>
                            </template>
                          </Popper>
                        </template>
                        <div
                          v-else-if="column.input"
                          class="input-group input-group-sm"
                          :style="{ width: column.width || 100 }"
                        >
                          <input
                            class="form-control m-0 p-0 px-1"
                            :type="column.input.type"
                            :placeholder="column.input.placeholder"
                            :value="getCellValue(column, line)"
                            v-bind:class="getBindClass(column, line)"
                            @input="setValue(column, line, $event)"
                          />
                          <button
                            v-if="line[column.key]"
                            class="btn btn-sm btn-outline-primary p-0 px-1"
                            type="button"
                            @click="clear(column, line)"
                          >
                            ×
                          </button>
                          <span
                            v-if="column.input.after_text"
                            class="
                              input-group-text input-group-text-sm
                              p-0
                              px-1
                            "
                            >{{ column.input.after_text }}</span
                          >
                        </div>
                        <div v-else>{{ getCellValue(column, line) }}</div>
                      </td>
                    </template>
                  </tr>
                </template>
              </tbody>
              <caption class="p-1 pt-0">
                {{
                  `Общее количество: ${length}`
                }}
              </caption>
            </table>
          </div>

          <div class="row row-cols-auto g-2 px-1" v-if="pagination">
            <ul
              class="
                pagination pagination-sm
                offset-md-0
                col-lg-auto
                justify-content-center
                mb-0
              "
            >
              <li class="page-item" role="button" v-if="pagination.page > 1">
                <a class="page-link" aria-label="Первая" v-on:click="toPage(1)">
                  <span>&laquo;</span>
                </a>
              </li>
              <li
                v-for="page_number in getPagesCount(pagination)"
                :key="page_number"
                class="page-item"
                role="button"
                v-bind:class="pagination.page == page_number ? 'active' : ''"
                v-on:click="toPage(page_number)"
              >
                <a class="page-link">{{ page_number }}</a>
              </li>
              <li
                class="page-item"
                role="button"
                v-if="pagination.page * pagination.page_size < pagination.count"
              >
                <a
                  class="page-link"
                  aria-label="Последняя"
                  v-on:click="toMaxPage"
                >
                  <span>&raquo;</span>
                </a>
              </li>
            </ul>
            <div class="col-lg-auto">
              <div class="input-group input-group-sm">
                <span class="input-group-text">Показывать по</span>
                <select
                  class="form-select"
                  v-if="page_sizes && pagination"
                  v-model="pageSize"
                  v-on:change="toPage(1)"
                >
                  <template v-for="page_size in page_sizes" :key="page_size">
                    <option
                      :value="page_size"
                      :selected="page_size == pagination.page_size"
                    >
                      {{ page_size }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TableFilter from "./TableFilter.vue";
import { tableSort } from "../utils.js";
import Popper from "vue3-popper";
export default {
  props: {
    virtual: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: String,
    },
    name: {
      type: String,
      default: null,
    },
    search_enabled: {
      type: Boolean,
      default: true,
    },
    excel_fields: {
      type: Object,
      default: null,
    },
    columns: {
      type: Array,
    },
    columns_parent: {
      type: Array,
    },
    data: {
      type: Array,
    },
    pagination: {
      type: Object,
    },
    page_sizes: {
      type: Array,
    },
    update: {
      type: Function,
    },
    buttons: {
      type: Array,
    },
  },
  components: { TableFilter, Popper },
  data() {
    return {
      page: 1,
      pageSize: 100,
      search: null,
      sort_by: null,
      sortDirection: "asc",
      filters: [],
      showFilters: false,
      filter_rules: {},
      sort: tableSort,
    };
  },
  computed: {
    getTableData: function () {
      let data = this.data;
      if (this.virtual && this.sort_by) {
        this.sort(data, this.sort_by, this.sortDirection);
      }
      if (!this.filter_rules) {
        return data;
      }
      for (const [key, value] of Object.entries(this.filter_rules)) {
        if (!value.length) continue;
        data = data.filter((line) => value.includes(line[key]));
      }

      return data;
    },
    length: function () {
      return this.pagination?.count || this.data?.length || 0;
    },
  },
  watch: {
    columns: function () {
      this.filters = this.columns.filter((column) => column.filter);
    },
  },
  methods: {
    clear(column, line) {
      if (column.input.clear) column.input.clear(line);
    },
    clickFilters() {
      this.showFilters = !this.showFilters;
      if (!this.showFilters) {
        this.filter_rules = {};
      }
    },
    addFilter(field, _, filtered_fields) {
      this.filter_rules[field] = filtered_fields;
    },
    getColumnRole(column) {
      return column.key ? "button" : column.role;
    },
    getPagesCount() {
      let pages = [];
      for (
        let index = 1;
        index <=
        Math.floor(this.pagination.count / this.pagination.page_size) +
          (this.pagination.count % this.pagination.page_size > 0 ? 1 : 0);
        index++
      ) {
        if (this.pagination.page <= index + 2 && pages.length < 5) {
          pages.push(index);
        }
      }
      return pages;
    },
    getMaxPage() {
      return (
        Math.floor(this.pagination.count / this.pagination.page_size) +
        (this.pagination.count % this.pagination.page_size > 0 ? 1 : 0)
      );
    },
    toPage(page) {
      this.page = page;
      this.reload();
    },
    toMaxPage() {
      this.page = this.getMaxPage();
      this.reload();
    },
    reload() {
      let params = this.pagination;
      if (this.pagination) {
        params.page = this.page;
        params.page_size = this.pageSize;
        params.search = this.search;
        params.sort_by = this.sort_by;
        params.sort_direction = this.sortDirection;
        Object.keys(params).forEach(
          (key) => params[key] == null && delete params[key]
        );
      }
      this.update(params);
    },
    setSort(column) {
      if (column.not_sort) {
        return;
      }
      if (this.sort_by == column.key) {
        switch (this.sortDirection) {
          case null:
            this.sortDirection == "asc";
            break;
          case "asc":
            this.sortDirection = "desc";
            break;
          case "desc":
            if (this.virtual) {
              this.sortDirection = "asc";
            } else {
              this.sort_by = null;
              this.sortDirection = null;
            }
            break;
          default:
            this.sort_by = null;
            this.sortDirection = null;
            break;
        }
      } else {
        this.sortDirection = "asc";
        this.sort_by = column.key;
      }
      if (!this.virtual) this.reload();
    },
    getSortArrow(sort_by) {
      if (!sort_by || !this.sort_by || sort_by != this.sort_by) return "";
      return this.sortDirection == "asc" ? " ↓" : " ↑";
    },
    getCellValue(column, line) {
      return column.value ? column.value(line) : line[column.key];
    },
    setValue(column, line, event) {
      column.update(line, event.target.value);
    },
    getCellClass(column, line) {
      return column.bind != undefined ? column.bind(line) : "";
    },
    getFileDateTime() {
      return moment(new Date()).format("DD.MM.YYYY HH-mm-ss");
    },
    buttonCallback(column, line) {
      if (column.button.callback) column.button.callback(column, line);
    },
    getBindClass(column, line) {
      return column.input.bind_class ? column.input.bind_class(line) : "";
    },
  },
};
</script>

<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #212529;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #dee2e6;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 0.5rem !important;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>

<style scoped>
.table > :not(:first-child) {
  border-top: 1px solid #ced4da;
}
.table {
  table-layout: auto;
  white-space: nowrap;
}

.table td,
.table th {
  white-space: nowrap;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}

.table thead th:last-child {
  width: 100%;
}
.hrf {
  text-decoration: none;
  color: inherit;
}
.hrf:visited {
  color: dimgray;
}
table {
  font-size: 13px;
}
thead {
  white-space: nowrap;
}
.input-group-text-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>

<style scoped>
</style>
