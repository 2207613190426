<template>
  <MainMenu />
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <loading :active="loading" :is-full-page="true"></loading>
  <div class="m-2">
    <Table
      name="Цены. OZON"
      :columns="columns"
      :data="getPricesOzon?.data"
      :pagination="getPricesOzon?.pagination"
      :page_sizes="[10, 25, 50, 100]"
      :update="updateOzonPrices"
    />
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import moment from "moment";
import UserService from "@/services/user.service";
import Table from "@/components/Table.vue";
import Loading from "vue-loading-overlay";
export default {
  data: () => {
    return {
      error: "",
      loading: false,
      columns: null,
    };
  },

  components: {
    Table,
    MainMenu,
    Loading,
  },

  mounted() {
    moment.locale("RU");
    this.columns = [
      { name: "Артикул", key: "id" },
      { name: "Наименование", key: "title", link: this.getOzonLink},
      { name: "Цвет", key: "color"},
      { name: "Цена", key: "price", bind: this.getPriceColor},
      { name: "Цена продажи", key: "price_sale", bind: this.getPriceSaleColor},
      { name: "Остаток Доставка", key: "rest_delivery", bind: this.getDeliveryColor},
      { name: "Остаток Экспресс", key: "rest_express", bind: this.getExpressColor},
      { name: "Последнее обновление", key: "last_update"},
    ];
    this.updateOzonPrices();
  },

  computed: {
    getPricesOzon() {
      return this.$store.getters["prices/getOzon"];
    },
  },

  methods: {
    updateOzonPrices: function () {
      this.loading = true;
      UserService.getPricesOzon(arguments)
        .then(
          (response) => {
            this.$store.commit("prices/setOzon", response.data);
          },
          (error) => {
            this.error = error.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    calcMoment: function (last_update) {
      return moment(new Date(last_update)).startOf("minute").fromNow();
    },
    getOzonLink: function (line) {
      return `https://www.ozon.ru/product/${line.id}/`;
    },
    getFormattedDate: function (line) {
      return moment(new Date(line.last_update)).startOf("minute").fromNow();
    },
    getDeliveryColor: function (line) {
      return (
        "table-" +
        (line.rest_delivery < 10 ? "danger" : line.rest_delivery < 20 ? "warning" : "")
      );
    },
    getExpressColor: function (line) {
      return (
        "table-" +
        (line.rest_express   < 10 ? "danger" : line.rest_express < 20 ? "warning" : "")
      );
    },
    getPriceColor: function (line) {
      return (
        "table-" +
        (!line.price_sale
          ? "danger"
          : line.price_sale == line.price
          ? "primary"
          : "")
      );
    },
    getPriceSaleColor: function (line) {
      return (
        "table-" +
        (line.price_sale > line.price
          ? "danger"
          : line.price_sale == line.price
          ? "primary"
          : "")
      );
    },
    getLastUpdateColor: function (line) {
      const difference =
        new Date().getTime() - new Date(line.last_update).getTime();
      return difference > 15*60*1000 ? "table-danger" : "";
    },
  },
};
</script>

<style scoped>
</style>
