import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import store from "@/store/index.js";
import setupInterceptors from "@/services/setupInterceptors";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import "vue-loading-overlay/dist/vue-loading.css";
import excel from 'vue-excel-export'
import Popper from "vue3-popper";

  // register globally
  

setupInterceptors(store);
const app = createApp(App);
app.use(router);
app.use(store);
app.use(BootstrapIconsPlugin);
app.use(excel)
app.use(Popper)
app.mount("#app");
